export const FETCH_USER = "FETCH_USER";
export const GET_AIRDROP = "GET_AIRDROP";
export const GET_STAKING_REFERRAL_REWARDS = "GET_STAKING_REFERRAL_REWARDS";
export const GET_GIFT_REWARDS = "GET_GIFT_REWARDS";
export const CHECK_GA_STATUS = "CHECK_GA_STATUS";
export const FETCH_DEVICE = "FETCH_DEVICE";
export const FETCH_LOGIN_ACTIVITY = "FETCH_LOGIN_ACTIVITY";
export const SET_INFO_VERIFICATION = "SET_INFO_VERIFICATION";
export const SET_LOCAL_IMAGE = "SET_LOCAL_IMAGE";
export const FETCH_FUND_LIST = "FETCH_FUND_LIST";
export const GET_DATA_FOR_COIN_CHART = "GET_DATA_FOR_COIN_CHART";
export const FETCH_TRANSACTION_HISTORY = "FETCH_TRANSACTION_HISTORY";
export const FETCH_SWAP_HISTORY = "FETCH_SWAP_HISTORY";
export const FETCH_WITHDRAW_HISTORY = "FETCH_WITHDRAW_HISTORY";
export const FETCH_DEPOSIT_HISTORY = "FETCH_DEPOSIT_HISTORY";
export const FETCH_SWAP_PRODUCT_LIST = "FETCH_SWAP_PRODUCT_LIST";
export const EASY_BUY = "EASYBUY";
export const BANK_TRANSFER = "BANK_TRANSFER";
export const MOMO = "MOMO";

export const SNTC = `SNTC`;
