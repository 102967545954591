/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Component_1 from "../components/homepage/Component_1";
import Information from "../components/homepage/Information";
import "../styles/homepage.scss";
import Component_4 from "../components/homepage/Component_4";
import Footer from "../components/Footer";
import Media from "../components/homepage/Media";
import CoinList from "../components/homepage/CoinList";

function Homepage() {
  return (
    <>
      <Information />
      <div className="homepage">
        <Component_1 />
        <CoinList />
        <Component_4 />
        <Media />
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
