import { Container, Grid, Link } from "@material-ui/core";
import React from "react";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Grid container justify="space-between">
          <Grid item className="color-white d-flex-sbt" xs={12} sm={4}>
            <Link target="_blank" href="/documents/TermsOfUse.pdf">
              Terms of Use
            </Link>
            <Link target="_blank" href="/documents/PrivacyPolicy.pdf">
              Privacy Policy
            </Link>
            <Link target="_blank" href="https://support.fastwallet.io/">
              Support
            </Link>
          </Grid>
          <Grid item xs={12} sm={8} className="text-right">
            © 2020 - 2021 <span translate="no">FAST Wallet</span>. All rights
            reserved
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer;
