import {
  Box,
  Drawer,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useState } from "react";

const CustomBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  position: "relative",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export default function NotifyPopup() {
  const [open, setOpen] = useState(true);
  return (
    <Drawer anchor={"left"} open={open} className={"custom-modal-vk"}>
      <Paper
        style={{
          width: "100%",
          maxWidth: 400,
          minWidth: 300,
        }}
      >
        <CustomBox>
          <IconButton
            onClick={() => setOpen(false)}
            // size="small"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <Close />
          </IconButton>
          <CustomTypography>패스트 월렛 공지 사항</CustomTypography>
          <CustomTypography>
            최근 락업 기간이 도래되어 sntc의 출금 신청 처리와 0.3이더 이상 출금
            신청 처리를 진행하기 위해서 Sntc의 기반 변경을 진행 중에 있습니다.
          </CustomTypography>
          <CustomTypography>
            출금 처리는 기반 변경이 완료되는 시점에 전체적으로 처리할 것입니다.
          </CustomTypography>
          <CustomTypography>기반 변경 완료 기간</CustomTypography>
          <CustomTypography>완료 기간 : 2022.11. 17일</CustomTypography>
          <CustomTypography>출금 신청 : 2022.11.18일 (금요일)</CustomTypography>
          <CustomTypography>
            패스트월렛의 스테이킹 서비스는 출금 처리가 완료되는 시점에 종료 할
            예정 입니다.
          </CustomTypography>
          <CustomTypography>
            업그레이드 되는 서비스는 비트로 진행되는 비트 커스더디 서비스를 다른
            플랫폼에서 공개하고, 서비스가 진행될 예정입니다.
          </CustomTypography>
        </CustomBox>
      </Paper>
    </Drawer>
  );
}
