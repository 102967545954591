export const ENDPOINT_GET_PROFILE = `/user-service/user/profile`;
export const ENDPOINT_GET_TRANSACTIONS = `/fund-service/transaction/list`;
export const ENDPOINT_GET_SECURITY = `/user-service/user/security`;
export const ENDPOINT_GET_LOGIN_DEVICE = `/user-service/device`;
export const ENDPOINT_GET_LOGIN_ACTIVITY = `/user-service/login-activity`;
export const ENDPOINT_POST_VERIFY = `/user-service/user/identity-verification`;
export const ENDPOINT_GET_POST_GA_CODE = `/user-service/user/ga`;
export const ENDPOINT_PUT_CHANGE_PASSWORD = `/user-service/user/change-pwd`;
export const ENDPOINT_GET_REFERRAL_LIST = `/user-service/referral/list`;
export const ENDPOINT_GET_FUND = `/fund-service/fund/list`;
export const ENDPOINT_GET_SWAP_HISTORY = `/swap-service/transaction/list`;
export const ENDPOINT_GET_WITHDRAW_HISTORY = `/fund-service/withdraw/list`;
export const ENDPOINT_GET_DEPOSIT_HISTORY = `/fund-service/deposit/list`;
export const ENDPOINT_GET_SWAP_PRODUCT_LIST = `/swap-service/product`;
export const ENDPOINT_ACTIVE_USER = `/user-service/user/active`;
export const ENDPOINT_POST_RESET_PASSWORD = `/user-service/user/reset-pwd`;
export const ENDPOINT_POST_LOGIN = `/user-service/user/login`;
export const ENDPOINT_GET_FORGOT_PASSWORD = `/user-service/user/forgot-pwd`;
export const ENDPOINT_GET_FUND_LOGS = `/fund-service/fund/log`;
export const ENDPOINT_DELETE_DISABLE_ACCOUNT = `/user-service/user/disable`;
